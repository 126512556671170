import clientInformation from '../login/ClientInfo';
import { RequestEWalletPayout } from "../../services/PayoutService";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Alerts from '../helpers/Alerts';
import Loading from '../helpers/Loader';
import { IsValidToken } from "../../services/AuthenticationService";
import BeatLoader from "react-spinners/BeatLoader";
import { onlyNumbers, BonusString } from '../helpers/common';
import { isMobile } from 'react-device-detect';

export const PayoutCryptoMethods = () => {
    const clientInfo = clientInformation();
    let INSTANCEID = clientInfo.InstanceID;
    let MAXLIMIT = process.env.REACT_APP_MaxLimit;
    const [isProcessing, setIsProcessing] = useState(false);
    const { id } = useParams();
    const [arrayPayouts, setPayouts] = useState(JSON.parse(localStorage.getItem('arrayPayoutsMethods')).filter(function (element) { return element.PayoutID === id; })[0]);
    const navigate = useNavigate();
    const [showComponent, setshowComponent] = useState(false);
    const [OriginalAmount, setOriginalAmount] = useState(0);

    useEffect(() => {
        IsValidToken(localStorage.getItem('Token')).then()
            .catch(function (error) {
                navigate('/expired/');
            })

        if (arrayPayouts === undefined) {
            navigate('/payouts/');
        }

    }, [])

    async function doRequestEWalletPayout(Amount, Criptoaddress, comments) {
        if (parseFloat(Amount.replace(",", "").replace(" ", "")) <= parseFloat(clientInfo.WithdrawalBalance.replace(",", "")) && parseFloat(Amount.replace(",", "").replace(" ", "")) > 0) {
            var PayoutData  = {
                "Token": localStorage.getItem('Token'),
                "Amount": Amount.replace(",", ""),
                "CurrencyCode": clientInfo.CurrencyCode,
                "ProcessorName": arrayPayouts?.PayoutID,
                "EWalletID": Criptoaddress,
                "SecureID": "",
                "IPv4Address": clientInfo.IPAddress,
                "Comments": comments
                };

            await RequestEWalletPayout(PayoutData).then(function (response) {

                if (response.Authentication.ErrorCode === "") {
                    if (isMobile)
                        Alerts.ShowAlertFullScreen('Withdrawal Status', response.Authentication.HtmlResponse, 'info');
                    else
                        Alerts.ShowAlert('Withdrawal Status', response.Authentication.HtmlResponse, 'info');
                    navigate('/payouts/');
                } else {
                    if (isMobile)   
                        Alerts.ShowAlertFullScreen('Withdrawal Status', response.Authentication.HtmlResponse, 'warning');
                    else
                        Alerts.ShowAlert('Withdrawal Status', response.Authentication.HtmlResponse, 'warning');
                }
            })
                .catch(function (error) {
                    Alerts.ShowAlert('Withdrawal Status', error.response.data.Authentication.HtmlResponse, 'error');
                    //Alerts.ShowAlert('Error', 'Error making deposit', 'error');
                })
        }
        else {
            if (isMobile)
                Alerts.ShowAlertFullScreen('Withdrawal Status', 'amount not permitted, please check your withdrawal balance', 'warning');
            else
                Alerts.ShowAlert('Withdrawal Status', 'amount not permitted, please check your withdrawal balance', 'warning');
        }

    }

    const handleSubmit = async (event) => {
        event.preventDefault();        
        if (!isProcessing) {
            var data = new FormData(event.target);
            let { amount, criptoaddress, comments } = Object.fromEntries(data.entries());
            
            if (amount != '') {
                setIsProcessing(true);
                setOriginalAmount(amount);
                await doRequestEWalletPayout(amount, criptoaddress, comments);
                setIsProcessing(false);
                

            }
            else {
                Alerts.ShowAlert('Warning', 'Amount required', 'warning');
            }

        }
    }

    return (
        <div className="componentload">
            <form onSubmit={handleSubmit}>
                <div className="row m-4">
                    <span className="icon-single-left txt-primary">
                        <img style={{ width: "100px", height: "100px" }} src={"/svg/" + arrayPayouts?.ImageName + ".svg"} />  <span className="payout-title">Withdraw using {arrayPayouts?.PayoutID} </span>
                    </span>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12 mb-3">
                        <input type="text" className="form-control" id="criptoaddress" aria-describedby="lblAddress" placeholder="Crypto Address" name="criptoaddress" required="required" />
                    </div>
                    <div className="col-md-6 col-sm-12 m3">
                        <div className="input-group mb-3">
                            <div className="input-group-prepend">
                                <span className="input-group-currency-cards d-none d-md-block" id="lblCurrency">
                                    <select className="form-control selectcurrency" disabled name="ccCurrency" required value={clientInfo.CurrencyCode}>
                                        <option value="" className="">Currency</option>
                                        <option value="USD">USD</option>
                                    </select>
                                </span>
                            </div>
                            <input type="number" onKeyPress={(event) => onlyNumbers(event)} aria-label="Amount (to the nearest dollar)" className="form-control" id="amount" aria-describedby="lblCurrency" placeholder="Amount" max={arrayPayouts?.MaxPayout === 0 ? MAXLIMIT : arrayPayouts?.MaxPayout} min={arrayPayouts?.MinPayout === 0 ? 1 : arrayPayouts?.MinPayout} name="amount" required="required" />
                        </div>

                        <div className="d-flex flex-row ml-2 limit-text-center mb-4 ">
                            PayoutLimits: Minimum {arrayPayouts?.MinPayout === 0 ? 1 : arrayPayouts?.MinPayout} USD Maximum {arrayPayouts?.MaxPayout === 0 ? 'No Limit' : arrayPayouts?.MaxPayout + ' USD'}
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-12 mb-3">
                        <textarea rows="3" cols="75" className="form-control" id="comments" aria-describedby="lblcomments" placeholder="Comments" name="comments" required="required" />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="alert alert-danger text-center" role="alert">
                            Only {arrayPayouts?.PayoutID} can be sent to provided address from the selected network.  Payouts of other crypto currencies or networks will be lost.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 " >

                    </div>
                    <div className="col-md-12 mb-3-small mt-4 actionbuttons">
                        <button type="button" className="btn btn-secondary btn-gray btn-back-order mb-4" onClick={() => navigate('/payouts/')}>BACK</button>
                        <button type="submit" className="btn btn-deposit btn-deposit-order mb-4 submit">SUBMIT</button>
                    </div>
                </div>


            </form>
            <div>
                {isProcessing && <Loading/>}
            </div>
        </div>
    );

}